<template>
  <section class="pedido-steps">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="section-title">
            <h4>{{ $t("resume") }}</h4>
            <a @click="volverListado()" href="javascript:void(0)">
              <img class="d-none d-sm-block" src="assets/img/backspace.svg" alt="backspace">
            </a>
          </div>
        </div>
      </div>
      <div class="loading"></div>
      <div class="row mostrarPedido d-none">
        <div class="col-md-12">
          <div class="confirmado">
            <div class="section-title p-0">
              <h5>{{ $t("order_resume") }}</h5>
            </div>

            <!-- desktop -->
            <table class="bg-white d-none d-sm-table" v-if="order">
              <tr>
                <th>EAN</th>
                <th>{{ $t("name") }}</th>
                <th>Material</th>
                <th>{{ $t("quantity") }}</th>
                <th>Unit</th>
                <th>Price</th>
              </tr>
              <tr class="b-top" v-for="(item,index) in order.items" :key="index">
                <td>{{ item.code_ean_sku }}</td>
                <td>{{ item.name_sku }}</td>
                <td>{{ item.code_sap_sku }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ item.unit }}</td>
                <td>{{ item.price }}</td>
              </tr>
            </table>

            <!-- mobile -->
            <div class="pedidosDetalle" v-if="order">
              <table class="bg-white d-block pedidosTableMobile b-top" v-for="(item,index) in order.items" :key="index">
                <tr>
                  <th>EAN</th>
                  <td>{{ item.code_ean_sku }}</td>
                </tr>
                <tr>
                  <th>{{ $t("name") }}</th>
                  <td>{{ item.name_sku }}</td>
                </tr>
                <tr>
                  <th>Material</th>
                  <td>{{ item.code_sap_sku }}</td>
                </tr>
                <tr>
                  <th>{{ $t("quantity") }}</th>
                  <td>{{ item.quantity }}</td>
                </tr>
                <tr>
                  <th>Unit</th>
                  <td>{{ item.unit }}</td>
                </tr>
                <tr>
                  <th>Price</th>
                  <td>{{ item.price }}</td>
                </tr>
              </table>
            </div>

            <div class="yellow-bg" v-if="order">
              <div class="inline-table">
                <span class="pull-left">{{ $t("delivery_date_mandatory") }}: <b>{{ order.delivery_date }}</b></span>
              </div>
              <div class="inline-table">
                <span class="pull-left">{{ $t("ship_to") }}: <b>{{ order.delivery_name }}</b></span>
              </div>
              <div class="inline-table">
                <span class="pull-left nro-orden">{{ $t("order_number") }}: <b>{{ order.order_number }}</b></span>
              </div>
              <div class="row mt-5">
                <div class="col-md-12">
                  {{ $t("status") }}:
                  <div class="inline-flex align-center label-content justify-center">
                    <a href="javascript:void(0)" :class="'label-' + order.state">{{ order.state_name }}</a>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 next-btn mt-5 mb-4">
                <a @click="volverListado()" href="javascript:void(0)" class="btn-white">{{ $t("back_to_list") }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <vue-basic-alert :duration="700" :closeIn="7000" ref="alert"/>
</template>
<script>
import $ from 'jquery'
import {route} from "@/enviroment"
import axiosInstance from '../../../main';
import authMixin from '@/mixins/authMixin';

export default {
  name: "PedidoImportadoDetalle",
  i18n: {
    messages: {
      en: {
        resume: "Summary",
        order_resume: "Order Summary",
        name: "Name",
        weight_gross: "Gross Weight",
        weight_net: "Net Weight",
        volume: "Volume",
        quantity: "Quantity",
        type: "Type",
        date: "Date",
        delivery_date_mandatory: "Required Delivery Date",
        ship_to: "Shipping Destination",
        order_number: "Order Number",
        total_weight_gross: "Total Gross Weight",
        total_weight_net: "Total Net Weight",
        total_volume: "Total Volume",
        status: "Status",
        back_to_list: "Back to List"
      },
      es: {
        resume: "Resumen",
        order_resume: "Resumen del pedido",
        name: "Nombre",
        weight_gross: "Peso Bruto",
        weight_net: "Peso Neto",
        volume: "Volumen",
        quantity: "Cant",
        type: "Tipo",
        date: "Fecha",
        delivery_date_mandatory: "Fecha requerida de entrega",
        ship_to: "Lugar de entrega",
        order_number: "Nro. Orden",
        total_weight_gross: "Peso Total Bruto",
        total_weight_net: "Peso Total Neto",
        total_volume: "Volumen Total",
        status: "Estado",
        back_to_list: "Volver al listado"
      }
    }
  },
  props: {
    order_id: String
  },
  data() {
    return {
      route: route,
      order: {}
    }
  },
  mixins: [authMixin],
  async created() {
    await this.validateUserAndSoldTo();
    this.getOrder()
  },
  methods: {
    showAlert(type, title, message) {
      this.$refs.alert.showAlert(
          type, // There are 4 types of alert: success, info, warning, error
          message, // Message of the alert
          title, // Header of the alert
          {
            iconSize: 35, // Size of the icon (px)
            iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
            position: 'top right' // Position of the alert 'top right', 'top left', 'bottom left', 'bottom right'
          }
      )
    },
    volverListado() {
      this.$emit("ocultarDetalleChild", false);
    },
    getOrder() {
      axiosInstance
          .post(this.$woe_api_uri + '/conversor/order/details', {'id': this.order_id})
          .then((response) => {
            $('.loading').addClass('d-none');
            $('.mostrarPedido').removeClass('d-none');
            this.order = response.data.data.order;
          })
          .catch((error) => {
            this.showAlert('warning', `${this.$t("caution")}!`, error.response.data.error.message);
          });
    }
  }

}
</script>