<template>
  <div class="pedido-listado">
    <section class="pedido-steps p-0">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="return d-block d-sm-none">
              <router-link :to="route">
                <img src="assets/img/backspace.svg" alt="backspace" class="mb-5">
              </router-link>
            </div>
            <div class="section-title">
              <router-link :to="route">
                <img class="d-none d-sm-block" src="assets/img/backspace.svg" alt="backspace">
              </router-link>
            </div>
          </div>
        </div>
        <div class="row" v-if="!this.loading">
          <div class="col-sm-3" v-if="locations.length > 1">
            <div class="form-group mt-4">
              <label>{{ $t("delivery_location") }}</label>
              <select v-model="form.location">
                <option v-for="(location, index) in locations" :key="index" :value="location">{{ location.name }}</option>
              </select>
            </div>
          </div>
          <div :class="{ 'col-sm-6': locations.length === 1, 'col-sm-6 offset-sm-3': locations.length > 1 }">
            <div class="section-title">
              <h5 class="pull-left">{{ $t("import_title") }}</h5>
              <a href="javascript:void(0)" @click="openFileUploader" class="btn-green ml-4">{{ $t("import") }}</a>
            </div>
          </div>
        </div>

        <form novalidate @submit.prevent="onSubmit">
          <file-upload id="fileupload" v-model="form.file" @change="importXlsx" style="display: none;"></file-upload>
        </form>

        <vue-basic-alert :duration="700" :closeIn="7000" ref="alert"/>

        <div class="row">
          <div class="col-sm-12 text-center mt-4">
            <h3>{{ $t("import_history") }}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="confirmado mt-2" v-if="!this.loading">
              <!--Para desktop-->
              <table class="bg-white d-none d-sm-table" id="pedidosTable">
                <thead>
                <tr style="background-color: #fffadc;">
                  <th class="text-center">{{ $t("filename") }}</th>
                  <th class="text-center">{{ $t("import_date") }}</th>
                  <th class="text-center">{{ $t("import_status") }}</th>
                </tr>
                </thead>
                <tbody id="mostrarTablas">
                <tr class="b-top cuota-row" v-for="(file,index) in this.imports" :key="index">
<!--                  <td class="text-center" @click="downloadFile(file.filename)">{{ file.filename }}</td>-->
                  <td class="text-center">{{ file.filename }}</td>
                  <td class="text-center">{{ file.creation_date }}</td>
                  <td class="text-center">{{ file.status }}</td>
                </tr>
                </tbody>
              </table>

              <!--Para mobile hay que hacer una tabla por pedido-->
              <table class="bg-white d-block d-sm-none pedidosTableMobile b-top cuota-row"
                     v-for="(file,index) in this.imports" :key="index">
<!--                <tr @click="downloadFile(file.filename)">-->
                <tr>
                  <th>{{ $t("filename") }}</th>
                  <td>{{ file.filename }}</td>
                </tr>
                <tr>
                  <th>{{ $t("import_date") }}</th>
                  <td>{{ file.creation_date }}</td>
                </tr>
                <tr>
                  <th>{{ $t("import_status") }}</th>
                  <td>{{ file.status }}</td>
                </tr>
              </table>

            </div>
            <div v-if="this.loading" class="loading"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import {route} from "@/enviroment"
import FileUpload from "../../layouts/FileUpload.vue"
import axiosInstance from "../../../main"

export default {
  name: "PedidoImportar",
  i18n: {
    messages: {
      en: {
        delivery_location: "Delivery Location",
        select_delivery_location: "Must be select a Delivery Location",
        import_title: "Import new file",
        import: "Import",
        import_history: "Import history",
        filename: "Filename",
        import_date: "Import Date",
        import_status: "Import Status",
        success_title: "Success!",
        success_download: "Download successful",
        warning_title: "Attention!",
        file_size_validation: "File is too large. Please upload a file less than 5 MB."
      },
      es: {
        delivery_location: "Lugar de Entrega",
        select_delivery_location: "Debe seleccionar un Lugar de Entrega",
        import_title: "Importar nuevo archivo",
        import: "Importar",
        import_history: "Historial de importaciones",
        filename: "Nombre del archivo",
        import_date: "Fecha de importación",
        import_status: "Estado de importación",
        success_title: "Éxito!",
        success_download: "Descarga exitosa",
        warning_title: "Atención!",
        file_size_validation: "El archivo es demasiado grande. Cargue un archivo de menos de 5 MB."
      }
    }
  },
  components: {
    FileUpload
  },
  data: function () {
    return {
      route: route,
      maxFileSize: 5 * 1024 * 1024, // 5 MB limit
      form: {
        file: null,
        sold_to: '',
        location: null
      },
      locations: [],
      imports: [],
      loading: false
    }
  },
  async created() {
    await this.setSoldTo();
    await this.getLocations();
    this.getImports();
  },
  methods: {
    setSoldTo() {
      this.form.sold_to = sessionStorage.getItem('pwa-woe-user-systemId');
    },
    openFileUploader() {
      document.getElementById('fileupload').click()
    },
    showAlert(type, title, message) {
      this.$refs.alert.showAlert(
          type, // There are 4 types of alert: success, info, warning, error
          message, // Message of the alert
          title, // Header of the alert
          {
            iconSize: 35, // Size of the icon (px)
            iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
            position: 'top right' // Position of the alert 'top right', 'top left', 'bottom left', 'bottom right'
          }
      )
    },
    cleanImportInput() {
      this.form.file = null;
      document.getElementById('fileupload').value = null;
    },
    getLocations() {
      this.loading = true;
      axiosInstance
          .get(this.$woe_api_uri + '/conversor/locations?sold_to=' + this.form.sold_to)
          .then((response) => {
            this.locations = response.data.data.locations;
            this.loading = false;
          })
          .catch((error) => {
            this.showAlert('warning', `${this.$t('warning_title')}!`, error.response?.data?.error?.message ?? error.message);
            if (error.code === "ERR_BAD_REQUEST") {
              location.href = route + "login";
            }
          });
    },
    getImports() {
      this.loading = true;
      axiosInstance
          .get(this.$woe_api_uri + '/conversor/imports?sold_to=' + this.form.sold_to)
          .then((response) => {
            this.imports = response.data.data.imports;
            this.loading = false;
          })
          .catch((error) => {
            this.showAlert('warning', `${this.$t('warning_title')}!`, error.response?.data?.error?.message ?? error.message);
            if (error.code === "ERR_BAD_REQUEST") {
              location.href = route + "login";
            }
          });
    },
    importXlsx() {
      this.loading = true;
      if (!this.form.sold_to) {
        sessionStorage.clear();
        location.href = route + "login";
        return;
      }
      if (this.form.file.size > this.maxFileSize) {
        this.loading = false;
        this.cleanImportInput();
        this.showAlert('warning', `${this.$t('warning_title')}`, `${this.$t('file_size_validation')}`);
        return;
      } else if (this.locations.length > 1 && !this.form.location) {
        this.loading = false;
        this.cleanImportInput();
        this.showAlert('warning', `${this.$t('warning_title')}`, `${this.$t('select_delivery_location')}`);
        return;
      } else if (this.locations.length === 1) {
        this.form.location = this.locations[0];
      }
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      axiosInstance
          .post(this.$woe_api_uri + '/conversor/import', this.form, config)
          .then((response) => {
            this.showAlert('success', `${this.$t('success_title')}!`, response.data.message);
            this.form.location = null;
            this.cleanImportInput();
            this.getImports();
            this.loading = false;
          })
          .catch((error) => {
            this.form.location = null;
            this.cleanImportInput();
            this.getImports();
            this.showAlert('warning', `${this.$t('warning_title')}`, error.response?.data?.error?.message ?? error.message);
            this.loading = false;
          });
    },
    // downloadFile(filename) {
    //   axiosInstance.get('/admin/download-file/' + filename)
    //       .then((response) => {
    //         const url = window.URL.createObjectURL(new Blob([response.data]));
    //         const a = document.createElement('a');
    //         a.href = url;
    //         a.download = filename;
    //         document.body.appendChild(a);
    //         a.click();
    //         window.URL.revokeObjectURL(url);
    //         this.showAlert('success', `${this.$t('success_title')}!`, `${this.$t('success_download')}!`);
    //       })
    //       .catch(error => {
    //         this.showAlert('warning', `${this.$t('warning_title')}!`, error.response?.data?.message ?? error.message);
    //       });
    // }
  }
}
</script>

<style scoped>
.cuota-row {
  cursor: pointer;
}

.cuota-row:hover {
  background-color: #e8e8e8;
}
</style>