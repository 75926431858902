import { createRouter, createWebHistory } from 'vue-router'
import IngresoComponent from '../components/pages/IngresoComponent.vue'
import SoldTos from '../components/pages/SoldTos.vue'
import PedidoNuevo from '../components/pedidos/PedidoNuevo.vue'
import PedidoImportar from '../components/pedidos/conversor/PedidoImportar.vue'
import PedidosMenu from '../components/pedidos/PedidosMenu.vue'
import PedidosListado from '../components/pedidos/PedidosListado.vue'
import PedidosImportados from '../components/pedidos/conversor/PedidosImportados.vue'
import ForgotPassword from '../components/auth/ForgotPassword.vue'
import ContactForm from '../components/layouts/ContactForm.vue'
//import ContactFormOld from '../components/layouts/ContactFormOld.vue'
import PedidosCambiosListado from '../components/pedidos/PedidosCambiosListado.vue'
import PedidosCambiosNuevo from '../components/pedidos/PedidosCambiosNuevo.vue'
import MisCasos from '../components/pages/MisCasos.vue'
import { route } from "../enviroment.js"
import axios from 'axios';
import PasswordReset from '@/components/auth/PasswordReset.vue'
// import Vue from 'vue';

const routes = [
  {
    path: route + 'login',
    name: 'login',
    component: IngresoComponent,
    meta: { requiresAuth: false }
  },
  {
    path: route + '',
    name: 'pedidos-menu',
    component: PedidosMenu,
    meta: { requiresAuth: true }
  },
  {
    path: route + 'pedidos-listado',
    name: 'pedidos-listado',
    component: PedidosListado,
    meta: { requiresAuth: true }
  },
  {
    path: route + 'pedidos-importados',
    name: 'pedidos-importados',
    component: PedidosImportados,
    meta: { requiresAuth: true }
  },
  {
    path: route + 'pedido-nuevo',
    name: 'pedido-nuevo',
    component: PedidoNuevo,
    meta: { requiresAuth: true }
  },
  {
    path: route + 'pedido-importar',
    name: 'pedido-importar',
    component: PedidoImportar,
    meta: { requiresAuth: true }
  },
  {
    path: route + 'forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: { requiresAuth: false }
  },
  {
    path: route + 'contacto',
    name: 'contact-form',
    component: ContactForm,
    meta: { requiresAuth: true }
  },
  // {
  //   path: route + 'contacto-2',
  //   name: 'contact-2-form',
  //   component: ContactForm,
  //   meta: { requiresAuth: true }
  // },
  {
    path: route + 'change-order-list',
    name: 'pedidos-cambios-listado',
    component: PedidosCambiosListado,
    meta: { requiresAuth: true }
  },
  {
    path: route + 'change-order-new',
    name: 'pedidos-cambios-nuevo',
    component: PedidosCambiosNuevo,
    meta: { requiresAuth: true }
  },
  {
    path: route + 'cases-list',
    name: 'cases-list',
    component: MisCasos,
    meta: { requiresAuth: true }
  },
  {
    path: route + 'sold-tos',
    name: 'sold-tos',
    component: SoldTos,
    meta: { requiresAuth: true }
  },
  {
    path: route + 'password-reset',
    name: 'password-reset',
    component: PasswordReset,
    meta: { requiresAuth: false }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

//check for user logged in on auth sections
router.beforeEach((to) => {
    // instead of having to check every route record with
    if (to.meta.requiresAuth) {
        if (!sessionStorage.getItem('name')) {
            location.href= route + "login";
        }
    }
});

//handle 401 response -> redirect 
axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        if (error.response.config.url.includes('login')) {
          return Promise.reject(error);
        }
        location.href= route + "login";
    } else {
        return Promise.reject(error);
    }
});

export default router
